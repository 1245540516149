document.addEventListener('DOMContentLoaded', function () {
  const production = document.querySelector('.production__presence');
  const btnOpenSidebar = production.querySelector('button');
  const btnCloseSidebar = document.querySelector('.menuMobilePresence > img');
  const sidebar = document.querySelector('.menuMobilePresence');
  const rightContent = production.querySelector('div');
  let openState = false;

  gsap.set(sidebar, { x: '-100%' });

  btnOpenSidebar.addEventListener('click', function () {
    const tl = gsap.timeline();
    tl.to(
      production,
      {
        x: '28rem',
        duration: 0.5,
        onComplete: function () {
          openState = true;
        },
      },
      '<'
    ).to(
      sidebar,
      {
        x: '0',
        duration: 0.5,
        onStart: function () {
          sidebar.style.visibility = 'visible';
        },
        onComplete: function () {
          document.querySelector('html').style.overflow = 'hidden';
        },
      },
      '<'
    );
  });

  btnCloseSidebar.addEventListener('click', function () {
    if (openState) {
      const tl = gsap.timeline();
      tl.to(production, {
        x: '0',
        duration: 0.5,
        onComplete: function () {
          openState = false;
        },
      }).to(
        sidebar,
        {
          x: '-100%',
          duration: 0.5,
          onComplete: function () {
            document.querySelector('html').style.overflow = 'auto';
          },
        },
        '<'
      );
    }
  });

  rightContent.addEventListener('click', function () {
    if (openState) {
      const tl = gsap.timeline();
      tl.to(production, {
        x: '0',
        duration: 0.5,
        onComplete: function () {
          openState = false;
        },
      }).to(
        sidebar,
        {
          x: '-100%',
          duration: 0.5,
          onComplete: function () {
            document.querySelector('html').style.overflow = 'auto';
          },
        },
        '<'
      );
    }
  });
});
